import React from "react"
import { graphql } from "gatsby"
import HTMLContent from '../../components/Content';
import Layout from '../../components/layout';

export default function infoPagesTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  // console.log('============================================================= infoPagesTemplate data')
  // console.log(data)
  
  const { markdownRemark } = data // data.markdownRemark holds your post data

  if (markdownRemark && markdownRemark !== null) {
    const {frontmatter} = markdownRemark;
        return (<Layout>
              <div className="main-container">
                  <div className="container">
                      <h2 className="heder-text">{frontmatter.banner.title}</h2>
                      <p className="body-text-data"><HTMLContent className="description" content={frontmatter.banner.description}/></p>
                      {
                          frontmatter.information.map((items,i)=>{
                          return (<div className="body-text" key={i}>
                                      <h4 className="body-header">{items.title}</h4>
                                      <p className="body-text-data"><HTMLContent className="description" content={items.description}/></p>
                                  </div>)
                          })
                      }
                  </div>
              </div>
            </Layout>)
  } else {
    return (
      <div></div>
    )
  }
  
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path }}) {
      html
      frontmatter {
        title
        banner {
          title
          description
        }
        information {
          title
          description
        }
      }
    }
  }
`